(function ($) {
    $(document).ready( function(){
        //************ START window.MODAL.call
        let openModalButtons =  Array.from(document.querySelectorAll('.js-modal'));

        openModalButtons.forEach( button =>{
            button.addEventListener('click',() =>{
                window.modalWindow.openMyModal( button.dataset.modal );
            })
        });

        //============ CLOSE on "close" button
        let body = document.querySelector('body');
        body.addEventListener('click',  function (evt) {
            if (window.modalWindow.checkClassList) {
                if (evt.target.className === 'modal__overlay') {
                    window.modalWindow.closeMyModal();
                }
            }
        });

        let closeBtn = Array.from(document.querySelectorAll('.js-modal__close')); // if class has children's

        closeBtn.forEach(btn => btn.addEventListener('click', () => window.modalWindow.closeMyModal()));
        //============ END NEW MODAL
    });
})(jQuery);


(function($) {
//************ START MODAL Methods
    let currentModal; // приватная переменная "#thankModal"
    let bodyElement = document.querySelector('body');
    const MODAL_OVERLAY_WRAPPER = `<div class='modal__overlay'></div>`;
    const MODAL_OVERLAY = `modal__overlay`;
    const MODAL_OPEN = `modal-open`;

    //============  ESCAPE key pressed
    let onPopupEscPress = (evt) => {
        if (window.modalWindow.checkClassList) {
            if (evt.keyCode === 27) window.modalWindow.closeMyModal();
        }
    };

    //============  on Resize
    let onOpenPopupResize = () => {
        if ( window.modalWindow.checkClassList ) window.modalWindow.setPosition()
    };

    window.modalWindow = { // методы доступные извне

        setId(id) {
            this.currentModal = id;
        },
        getId() {
            return this.currentModal;
        },

        openMyModal(id) { //============ OPEN
            let modalPrev = window.modalWindow.getId();

            if(modalPrev) {
                window.modalWindow.closeMyModal();
            }

            window.modalWindow.setId(id);
            if ($(id)[0].parentElement.className !== MODAL_OVERLAY) {
                $(id).wrap(MODAL_OVERLAY_WRAPPER);
            }

            setTimeout(function () {
                let overlay = $(id).parent();
                $(id).css('display', 'block');
                overlay.fadeIn(400, window.modalWindow.setPosition());
                bodyElement.classList.add(MODAL_OPEN);

            }, 500);

            window.addEventListener("resize", onOpenPopupResize);
            document.addEventListener('keydown', onPopupEscPress);
        },

        closeMyModal() { //============ CLOSE
            let modal = $( window.modalWindow.getId() );

            modal.animate({opacity: 0, top: '45%'}, 200);
            modal.parent(`.${MODAL_OVERLAY}`).fadeOut(400);
            bodyElement.classList.remove(MODAL_OPEN);
            setTimeout(function () {
                modal.removeAttr('style');
            }, 400);

            window.removeEventListener("resize", onOpenPopupResize);
            document.removeEventListener('keydown', onPopupEscPress);
        },

        setPosition() { //========= Position

            let modal = $( window.modalWindow.getId() ),
                position;

            setTimeout(function () {
                modal.stop();
                if (modal[0].clientHeight > window.innerHeight) {
                    position = { // "TOP"
                        top: '20px',
                        marginTop: 0,
                        opacity: 1
                    };
                } else { // "CENTER"
                    position = {
                        top: window.innerHeight / 2 + 'px',
                        opacity: 1,
                        marginTop: `-${modal[0].clientHeight / 2}px`
                    };
                }

                modal.animate( position, 350);
            }, 350);
        },

        checkClassList({ bodyElement }){
            if (bodyElement.classList.contains(MODAL_OPEN)) return true
        }
    };

//============  END START MODAL Methods
})(jQuery);
