(function ($) {
    $(document).ready(function () {
        $('.js-masked').mask('+38(999)999-99-99', {
            placeholder: "+38(___)___-__-__"
        });

        let timePicker = $('.js-wickedpicker');

        timePicker.wickedpicker({
            now: "09:00",
            twentyFour: true,
            minutesInterval: 10
        });
        timePicker.val('');

        $('.js-datepicker').datepicker({
            minDate: new Date(),
            startDate: new Date(),
            toggleSelected: false,
            dateFormat: 'dd.mm.yyyy',
            autoClose: true,
        }).attr('readonly', true);

        $('.js-modal--mobile').on('click', function () {
            setTimeout(() => $('.off-canvas-close').click(), 400)
        });

        $(".js-select2").select2();
    });

    document.addEventListener( 'wpcf7submit', function(event) {
        if(![4317, 3706, 3167, 4163].includes(+event.detail.contactFormId)) {
            return;
        }

        fbq('track', 'Lead');
        console.log(`Form ${+event.detail.contactFormId} lead was tracked`)
    }, false);
})(jQuery);
