(function ($) {
    $('button.search-submit').on('click', function (evt) {
        evt.preventDefault();

        let input =  $(this).parent().find('input[type="search"]');
        console.log(input.val().length);

        if(input.val().length > 0 ){
            $(this).parent().submit();
        }
    });

    $('form.search-form').find('button').on('click', function (evt) {
        evt.preventDefault();

        let input =  $(this).parent().find('input.search-field');
        console.log(input.val().length);

        if(input.val().length > 0 ){
            $(this).parent().submit();
        }

    });

})(jQuery);
