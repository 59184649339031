(function() {
    document.addEventListener('DOMContentLoaded', function() {
        require('./components/slider');
        require('./components/elements');
        require('./components/modal');
        require('./components/form');
        require('./components/form-search');
        require('./components/body-scroll-lock');
    });
})();
