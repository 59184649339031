(function ($) {
    $(document).ready(function () {
        let sliders = $(".cvca-carousel-block");

        if (sliders.length) {
            sliders.each(function (i, elem) {
                $(elem).slick('unslick');

                let data = JSON.parse($(elem).attr('data-config'));
                let item = 4;
                let center_mode = false;
                let center_padding = '60px';
                let pag = false;
                let nav = false;
                let autoplay = false;

                if (data['center_mode'] != undefined && data['center_padding'] != undefined && data['center_mode'] == '1') {
                    center_padding = data['center_padding'];
                }

                if (data['item'] != undefined && data['item'] != '')  item = parseInt(data['item']);

                if (data['center_mode'] != undefined && data['center_mode'] == '1')  center_mode = true;

                if (data['pagination'] != undefined && data['pagination'] == '1')  pag = true;

                if (data['navigation'] != undefined && data['navigation'] == '1') nav = true;

                if (data['autoplay'] != undefined && data['autoplay'] == '1') autoplay = true;


                let wrap = data['wrap'] != undefined ? data['wrap'] : '';
                let wrapcaroul = wrap != '' ? $(elem).find(wrap) : $(elem);
                wrapcaroul.slick({
                    slidesToShow: item,
                    centerMode: center_mode,
                    centerPadding: center_padding + 'px',
                    arrows: nav,
                    dots: pag,
                    rows: 0,
                    autoplay: autoplay,
                    lazyLoad: 'ondemand',
                    infinite: true,
                    prevArrow: '<span class="cvca-carousel-btn prev-item"><i class="cs-font clever-icon-arrow-left-1"></i></span>',
                    nextArrow: '<span class="cvca-carousel-btn next-item "><i class="cs-font clever-icon-arrow-right-1"></i></span>',
                    autoplaySpeed: 5000,
                    rtl: $('body.rtl')[0] ? true : false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: item >= 4 ? 3 : item,
                                slidesToScroll: item >= 4 ? 2 : 1
                            }
                        }, {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: item > 2 ? 2 : item,
                                slidesToScroll: 1,
                            }
                        }, {
                            breakpoint: 560,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                            }
                        }
                    ]
                });
            })
        }
    })


})(jQuery);

